const cookie = {

	elem: {
		$accept: $('#accept'),
		$decline: $('#decline'),		
		$popup: $('.cookie')
	},

	init() {

		if (Cookies.get('cookie') !== undefined) {		
			cookie.elem.$popup.hide();
		}
		
		this.elem.$accept.on('click', function(e) {
			e.preventDefault();
			cookie.elem.$popup.addClass('cookie--selected');
			Cookies.set('cookie', 'true', { expires: 14, Secure: false, HttpOnly: false });
			
			window.setTimeout(function() {
				cookie.elem.$popup.hide();
			},250);
		});

		this.elem.$decline.on('click', function(e) {
			e.preventDefault();
			cookie.elem.$popup.addClass('cookie--selected');
			
			window.setTimeout(function() {
				cookie.elem.$popup.hide();
			},250);
		});
	}

};

export { cookie };
