const share = {

	elem: {
		$mailto: $('.social__item .btn--social[href="mailto:"]')
	},

	init() {
		let title = $('h1').text().trim();
		let url = window.location.href;
		this.elem.$mailto.attr('href','mailto:?subject=' + encodeURIComponent(title) + '&body=' + encodeURIComponent(url));
	}

};

export { share };
