import { ready } from 'brei-project-utils';

import { plugin } from './lib/plugin';

import { accordions } from './modules/accordions';
import { cookie } from './modules/cookie';
import { masthead } from './modules/masthead';
import { primaryNav } from './modules/primary-nav';
import { search } from './modules/search';
import { share } from './modules/share';
import { takeover } from './modules/takeover';
import { Tooltip } from './modules/tooltip';
import { video } from './modules/video';

const main = {

	elem: {
		$textTooltip: $('.text-tooltip'),
		$tooltip: $('.tooltip')		
	},

	init() {
		// Init plugins
		plugin('Tooltip', Tooltip);

		accordions.init();
		cookie.init();
		masthead.init();
		primaryNav.init();		
		search.init();
		share.init();		
		takeover.init();
		video.init();
		
		this.bindUIActions();
	},

	bindUIActions() {
		this.elem.$tooltip.Tooltip({ type: 'button' });

		this.elem.$textTooltip.Tooltip({
			type: 'text',
			after(target) {
				$(target).toggleClass('text-tooltip--is-active');
			}
		});
	}

};

ready(function () {
	main.init();
});
